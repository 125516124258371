import React from 'react';

function About() {

  return (
    <div className="About">
      <p>Ding Dong</p>
    </div>
  );
}

export default About;
