import React from 'react';

function Blog() {

  return (
    <div className="Blog">
      <p>Words and things about stuff</p>
    </div>
  );
}

export default Blog;
